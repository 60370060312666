import React, { useEffect,useState,useContext } from 'react';
import './panel.css';
import { useGetData, usePostData } from '../dataconn/getData';
import { useNavigate } from 'react-router';

import Alert from '../Alert/appAlert';
import {useReportErr} from '../Report/useReportErr';
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import { RotatingLines } from  'react-loader-spinner'
import { FaFilePdf } from "react-icons/fa";
import { saveAs } from 'file-saver';
import axios from 'axios';
import formatDateTime from '../functions/formatDateTime';

// we will use context
export const MyContext = React.createContext();


export default function Panel() {

const [spinnerLoading, setSpinnerLoading] = useState(false);
const [dashBoardInfo, setDashBoardInfo] = useState({});
const [totalUsers, setTotalUsers] = useState(0);
const [totalIngredients, setTotalIngredients] = useState(0);
const [totalRecipes, setTotalRecipes] = useState(0);
const [totalProducts, setTotalProducts] = useState(0);
const [totalProductions, setTotalProductions] = useState(0);
const [activeServices, setActiveServices] = useState(0);
const [activeButton, setActiveButton] = useState(0);
const [getInvoiceStatus, setInvoiceStatus] = useState(false);
const [getshowAlert, setShowAlert] = useState(false);
const [getAlertMessage, setAlertMessage] = useState('');
const [getAlertCode, setAlertCode] = useState(0);
const [getUserInformation, setUserInformation] = useState([{}]);
const[userInfoById,setUserInfoById]=useState([{}]);
const [listUsers, setListUsers] = useState(false);
const [getCountry, setCountry] = useState('');
const [mobileIsUsed, setMobileIsUsed] = useState(false);
const [chosenUserid, setChosenUserid] = useState(0);
const [getSearchUser, setSearchUser] = useState(false);
const [getSearchUserText, setSearchUserText] = useState('');
const [getShowSearchList, setShowSearchList] = useState(false);
const [userTurnedOff, setUserTurnedOff] = useState(false);
const [getTurnedOffStatus, setTurnedOffStatus] = useState(0);
const[getSignUpStatus,setSignUpStatus]=useState(0);
const[supportList,setSupportList]=useState([{}]);
const [getShowSupportTicket, setShowSupportTicket] = useState(false);
const [getSupportTicket, setSupportTicket] = useState(0);
const[getSupportTicketInfo,setSupportTicketInfo]=useState([{}]);
const[getSupportCategories,setSupportCategories]=useState([{}]);
const[getSupportStatus,setSupportStatus]=useState(0);   
const[latestRates,setLatestRates]=useState([{}]);
const [scrollLimit, setScrollLimit] = useState(0);




let navigate = useNavigate();
 

        useEffect(()=>{
            let countryInstorage = localStorage.getItem('country')||'en' // this is the country code
            setCountry(countryInstorage)

            // font settings
                 // if language is japanese we use the font Zen Old Mincho
        let newFontFamily=''
        if(getCountry==='ja'){
        newFontFamily= "'Zen Old Mincho', Arial, Helvetica, sans-serif"
        }else{
        newFontFamily='Arial, Helvetica, sans-serif'
        }
        document.documentElement.style.setProperty('--font-family', newFontFamily);
        },[])

       
       const confirmationMessages = languages?.[getCountry]?.confirmationMessages || {};
       const { downloadpdfOkMessage,
        userTurnedOffMessage,
        userTurnedOnMessage,
        serviceTurnedOffText,
        serviceTurnedOnText,
        serviceProlongedText,
        signUpStatusUpdateText,
        supportTicketUpdateOkText
       } = confirmationMessages;

       const panel = languages?.[getCountry]?.panel || {};
       const { supportStatusText,
        supportCategoryText

       } = panel;

        const selectedError=AppError[localStorage.getItem('country')||'en']

     useEffect(() => {
   
        const authToken = localStorage.getItem('auth_token');
        if (!authToken) {
            localStorage.removeItem('auth_token');
            navigate('/');
        }else{
            CheckAdminLogin();
        
        }
     }, []);

     useEffect(() => {
        function setSize() {
            if (window.innerWidth < 600) {
                setMobileIsUsed(true);
            } else {
                setMobileIsUsed(false);
            }
        }
    
        // Set initial size
        setSize();
    
        // Add event listener for window resize
        window.addEventListener('resize', setSize);
    
        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', setSize);
        };
    }, []);

    async function GetPanelDashBoardInfo() {
        setSpinnerLoading(true);
        try {
            const response = await useGetData('getPanelDashBoardInfo');
            console.log(response);
          
                setTotalUsers(response.data[0][0].totalUsers);
                setTotalIngredients(response.data[1][0].totalIngred);
                setTotalRecipes(response.data[2][0].totalRecept);
                setTotalProducts(response.data[3][0].totalProducts);
                setTotalProductions(response.data[4][0].totalProductions);
                setActiveServices(response.data[5][0].activeServices);
                setInvoiceStatus(response.data[6][0].invoicingStatus === 1);
                setUserInformation(response.data[7])
                setSignUpStatus(response.data[8][0].signUpStatus);
          
                setSpinnerLoading(false);
            
        } catch (error) {
            console.log(error);
        }
    }

     useEffect(() => {

       GetPanelDashBoardInfo();
   }, []);

     async function CheckAdminLogin() {

            setSpinnerLoading(true);
            try{
                let response=await useGetData('checkAdminLogin');  
                if(response.status===200){
                    setSpinnerLoading(false);
             
                }else if(response.status===290){
                    setSpinnerLoading(false);
                    localStorage.removeItem('auth_token');
                    navigate('/');
                }
            }catch(error){
                console.log(error);
            }
        }

    
            async function UpdateInvoicingStatus(status) {
               setSpinnerLoading(true);
                try {
                   let response= await usePostData('UpdateInvoicingStatus',{invoicingStatus:status});
                        setSpinnerLoading(false);
                        if(response.status===290){
                            setAlertCode(450001);
                            setAlertMessage(AppError[450001]);
                            setShowAlert(true);
                        }
                } catch (error) {
                    setAlertCode(450000);
                    setAlertMessage(AppError[450000]);
                    setShowAlert(true);
                    setSpinnerLoading(false);
                    
                }
            }
    



const logoutHandler=()=>{
    localStorage.removeItem('auth_token');
    navigate('/');
}

const panelButtonHandler=(e)=>{
    setActiveButton(e);
    if(e===4){
        setSearchUser(false);
        setListUsers(false);
    }else if(e===6){
        GetSignUpStatus();
    }else if(e===7){
        GetSupportList();     
   }else if (e===8){
       XchangeRates();
   }
}


async function XchangeRates(){
    setSpinnerLoading(true);
    try{
        let response=await useGetData('getExchangeRates');
        setSpinnerLoading(false);
        if(response.status===200){
            setAlertCode(0);
            setAlertMessage('Xchange rates updated');
            setShowAlert(true);
        }
        if(response.status===290){
            setAlertCode(450007);
            setAlertMessage(selectedError[450010]);
            setShowAlert(true);
        }
    }catch(error){
        setAlertCode(450007);
        setAlertMessage(selectedError[450010]);
        setShowAlert(true);
        setSpinnerLoading(false);
    }
}






async function GetSupportList(){
    setSpinnerLoading(true);
    try{
        let response=await useGetData('getSupportList');
        setSupportList(response.data[0]);  
        setSupportCategories(response.data[1]);
        setSupportStatus(response.data[2]);
        setSpinnerLoading(false);
        if(response.status===290){
            setAlertCode(450008);
            setAlertMessage(selectedError[450008]);
            setShowAlert(true);
        }
    }catch(error){
        setAlertCode(450008);
        setAlertMessage(selectedError[450008]);
        setShowAlert(true);
        setSpinnerLoading(false);
    }
}

const setInvoiceStatusHandler=(e)=>{
    //toggle the status
 if(e===0){
     setInvoiceStatus(false);
     UpdateInvoicingStatus(false);
 }else if(e===1){
        setInvoiceStatus(true);
        UpdateInvoicingStatus(true);
    }


}


const RunReportErrHandler = async (errCode) => {
      
    setSpinnerLoading(true);
    await useReportErr('errReport',{errCode});
    setSpinnerLoading(false);
  };
  
  useEffect(() => {
    
    if (getAlertCode > 0) {
      RunReportErrHandler(getAlertCode);
    }
  }, [getAlertCode]);
  
  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  async function GetUserInfo(userId,cc){
    setChosenUserid(userId);
    setSpinnerLoading(true);
    try{
    let response=await usePostData('getUserInfoById',{userId,cc});
    setUserInfoById(response.data);
    setListUsers(false);
    setSpinnerLoading(false);
    if(response.status===290){
        setAlertCode(450002);
        setAlertMessage(selectedError[450002]);
        setShowAlert(true);
        setSpinnerLoading(false);
    }
}catch(error){
    setAlertCode(450002);
    setAlertMessage(selectedError[450002]);
    setShowAlert(true);
    setSpinnerLoading(false);

}
  }

const listUsersHandler=()=>{
    setListUsers(true)
    setSearchUser(false);
 }
 const SearchUserHandler=()=>{
    setListUsers(false);
    setSearchUser(true);
    setUserInfoById([{}]);
}
  

 // download pdf invoice
 async function DownLoadPdfHandler(userid,invoiceid){
    console.log(invoiceid)
  
    setSpinnerLoading(true);
    try{
        const token = localStorage.getItem('auth_token');
        const headers = token ? { authorization: `Bearer ${token}` } : {};  
        let response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/downloadCustomerPdfInvoice/${chosenUserid}/${invoiceid}`,{ headers, responseType: 'blob' });   
        if(response.status===200){
           const blob = new Blob([response.data], {type: 'application/pdf'});
           saveAs(blob, `Bakesoft_invoice_${invoiceid}.pdf`);

           setSpinnerLoading(false)
           setAlertCode(0)
           setAlertMessage(downloadpdfOkMessage)
           setShowAlert(true)
       }
   }catch(error){
    console.log(error)
       setSpinnerLoading(false)
       setAlertCode(450003)
       setAlertMessage(selectedError[450003])
       setShowAlert(true)
   }

   }


async function PanelSearchUser(){
    setSpinnerLoading(true);
    try{
        let response=await usePostData('searchUser',{searchText:getSearchUserText});
        setUserInfoById(response.data);
        setChosenUserid(response.data[0][0].userId)
        setSpinnerLoading(false);
        setShowSearchList(true);
        if(response.status===290){
            setAlertCode(450003);
            setAlertMessage(selectedError[450004]);
            setShowAlert(true);
            setSpinnerLoading(false);
        }
    }catch(error){
        setAlertCode(450003);
        setAlertMessage(selectedError[450004]);
        setShowAlert(true);
        setSpinnerLoading(false);

    }
}

const SearchUserTextHandler=(e)=>{
    setSearchUserText(e);
}

async function  TurnOfUserHandler(e){
    setSpinnerLoading(true);
    try{
        let response=await usePostData('turnOffUser',{turnOffUserId:chosenUserid,turnOfStatus:e});
         setUserTurnedOff(true);
        setSpinnerLoading(false);
        if(response.status===200){
            setAlertCode(0);
            setAlertMessage(userTurnedOffMessage);
            setShowAlert(true);
        }else if (response.status===201){
            setAlertCode(0);
            setAlertMessage(userTurnedOnMessage);
            setShowAlert(true);
        }
    }catch(error){
        setAlertCode(450005);
        setAlertMessage(selectedError[450005]);
        setShowAlert(true);
        setSpinnerLoading(false);
    }
}

async function ServicesHandler(e,rowId){
    console.log(rowId);
    setSpinnerLoading(true);
    try{
        let response=await usePostData('getServices',{userId:chosenUserid,serviceStatus:e,rowsId:rowId});
        console.log(response);
       if(response.status===200){
           setSpinnerLoading(false);
           setAlertCode(0);
           setAlertMessage(serviceTurnedOffText);
           setShowAlert(true);
       }else if(response.status===201){
              setSpinnerLoading(false);
              setAlertCode(0);
              setAlertMessage(serviceTurnedOnText);
              setShowAlert(true);
       }else if(response.status===202){
                setSpinnerLoading(false);
                setAlertCode(0);
                setAlertMessage(serviceProlongedText);
                setShowAlert(true);
         }
         else if(response.status===290){
            setAlertCode(450005);
            setAlertMessage(selectedError[450005]);
            setShowAlert(true);
            setSpinnerLoading(false);
         }
}catch(error){
    setAlertCode(450005);
    setAlertMessage(selectedError[450005]);
    setShowAlert(true);
    setSpinnerLoading(false);
}
}

async function GetSignUpStatus(){
    setSpinnerLoading(true);
    try{
        let response=await useGetData('getSignUpStatus');
   
            setTurnedOffStatus(response.data[0]);
            setSpinnerLoading(false);
            if(response.status===290){
                setAlertCode(450006);
                setAlertMessage(selectedError[450006]);
                setShowAlert(true);
                setSpinnerLoading(false);
            }
        
    }catch(error){
        setAlertCode(450006);
        setAlertMessage(selectedError[450006]);
        setShowAlert(true);
        setSpinnerLoading(false);
    }
}

async function UpdateSignUpStatus(signUp){
    console.log(signUp)
    setSpinnerLoading(true);
    try{
        let response=await usePostData('updateSignUpStatus',{signUpStatus:signUp});
        if(response.status===200){
            setAlertCode(0);
            setAlertMessage(signUpStatusUpdateText);
            setShowAlert(true);
            setSpinnerLoading(false);
            GetPanelDashBoardInfo();
        }
        if(response.status===290){
            setAlertCode(450006);
            setAlertMessage(selectedError[450007]);
            setShowAlert(true);
            setSpinnerLoading(false);
        }
    }catch(error){
        setAlertCode(450006);
        setAlertMessage(selectedError[450007]);
        setShowAlert(true);
        setSpinnerLoading(false);
    }
}

const SupportTextAreaHandler=(e,supportid)=>{
    console.log(e,supportid)
 let myNewObjArray = [...supportList];
 let myNewObjIndex = myNewObjArray.findIndex(obj => obj.supportid === supportid);
 console.log(myNewObjIndex)
    myNewObjArray[myNewObjIndex].benaemn=e;
    console.log(myNewObjArray)
    setSupportList(myNewObjArray);
}

const SupportTicketHandler=(supportid)=>{
    setShowSupportTicket(true);
    setSupportTicket(supportid)
    let supportInfo=supportList.filter((support)=>support.supportid===supportid);
    setSupportTicketInfo(supportInfo);
}

const UpdateSupportCategoryHandler=(categoryid,supportid)=>{
    console.log(categoryid,supportid)
    // we will update the object in the array
    let myNewObjArray = [...supportList];
    let myNewObjIndex = myNewObjArray.findIndex(obj => obj.supportid === supportid);
    myNewObjArray[myNewObjIndex].kategoriid=categoryid;

}

const UpdateSupportTicketHandler=(supportid)=>{
    // we will take out the info from the object by the supportid
    let supportTicketInfo=supportList.filter((support)=>support.supportid===supportid);
    UpdateSupportTicket(supportTicketInfo);
}   


const UpdateSupportStatusHandler=(status,supportid)=>{
    console.log(supportid,status)
    // we will update the object in the array
    let myNewObjArray = [...supportList];
    let myNewObjIndex = myNewObjArray.findIndex(obj => obj.supportid === supportid);
    myNewObjArray[myNewObjIndex].Istatus=parseInt(status)
    console.log(myNewObjIndex)
console.log(myNewObjArray)
}

async function UpdateSupportTicket(supportTicketInfo){
    setSpinnerLoading(true);
    try{
        let response=await usePostData('updateSupportTicket',{supportTicket:supportTicketInfo});
        console.log(response);
        if(response.status===200){
            setAlertCode(0);
            setAlertMessage(supportTicketUpdateOkText);
            setShowAlert(true);
            setSpinnerLoading(false);
            GetSupportList();
       
        }
        if(response.status===290){
            setAlertCode(450008);
            setAlertMessage(selectedError[450009]);
            setShowAlert(true);
            setSpinnerLoading(false);
        }
    }catch(error){
        setAlertCode(450008);
        setAlertMessage(selectedError[450009]);
        setShowAlert(true);
        setSpinnerLoading(false);
    }
}


async function FetchXchangeRates(){
    console.log('fetch rates')
    setSpinnerLoading(true);
    try{
        let response=await useGetData('getExchangeRatesForView');
        setLatestRates(response.data[0]);
        setSpinnerLoading(false);
        if(response.status===290){
            setAlertCode(450010);
            setAlertMessage(selectedError[450010]);
            setShowAlert(true);
            setSpinnerLoading(false);
        }
    }catch(error){
        setAlertCode(450010);
        setAlertMessage(selectedError[450010]);
        setShowAlert(true);
        setSpinnerLoading(false);
    }
}





const GetXchangeRatesHandler=()=>{
    console.log('get rates')
    FetchXchangeRates();
}




async function GetScrollLimit(){
       setSpinnerLoading(true);
    try{
        let response=await useGetData('getScrollLimit');
        console.log(response);
        setScrollLimit(response.data.scrollLimit);
        setSpinnerLoading(false);
        if(response.status===290){
            setAlertCode(450010);
            setAlertMessage(selectedError[450011]);
            setShowAlert(true);
            setSpinnerLoading(false);
        }

}
catch(error){
    setAlertCode(450010);
    setAlertMessage(selectedError[450011]);
    setShowAlert(true);
    setSpinnerLoading(false);
}
}

async function SetScrollLimit(){
    setSpinnerLoading(true);
    try{
        let response=await usePostData('setScrollLimit',{scrollLimit:scrollLimit});
        console.log(response);
        setScrollLimit(response.data.scrollLimit);
        setSpinnerLoading(false);
        setAlertCode(0);
        setAlertMessage('Scroll limit updated');
        setShowAlert(true);
        if(response.status===290){
            setAlertCode(450011);
            setAlertMessage(selectedError[450012]);
            setShowAlert(true);
            setSpinnerLoading(false);
        }

}catch(error){
    setAlertCode(450011);
    setAlertMessage(selectedError[450012]);
    setShowAlert(true);
    setSpinnerLoading(false);
}
}


  return (
    <MyContext.Provider value={{
     totalUsers: totalUsers,
    totalIngredients: totalIngredients,
    totalRecipes: totalRecipes,
    totalProducts: totalProducts,
    totalProductions: totalProductions,
    activeServices: activeServices,
    getInvoiceStatus: getInvoiceStatus,
    setInvoiceStatusHandler: setInvoiceStatusHandler,
    getUserInformation: getUserInformation,
    setAlertCode: setAlertCode,
    setAlertMessage: setAlertMessage,
    setShowAlert: setShowAlert,
    setSpinnerLoading: setSpinnerLoading,
    selectedError: selectedError,
    GetUserInfo:GetUserInfo,
    userInfoById:userInfoById,
    setListUsers:setListUsers,
    listUsers:listUsers,
    listUsersHandler:listUsersHandler,
    mobileIsUsed:mobileIsUsed,
    DownLoadPdfHandler:DownLoadPdfHandler,
    SearchUserHandler:SearchUserHandler,
    getSearchUser:getSearchUser,
    PanelSearchUser:PanelSearchUser,
    SearchUserTextHandler:SearchUserTextHandler,
    getSearchUserText:getSearchUserText,
    getShowSearchList:getShowSearchList,
    TurnOfUserHandler:TurnOfUserHandler,
    userTurnedOff:userTurnedOff,
    setTurnedOffStatus:setTurnedOffStatus,
    getTurnedOffStatus:getTurnedOffStatus,
    ServicesHandler:ServicesHandler,
    chosenUserid:chosenUserid,
    getSignUpStatus:getSignUpStatus,
    UpdateSignUpStatus:UpdateSignUpStatus,
    supportList:supportList,
    SupportTextAreaHandler:SupportTextAreaHandler,
    SupportTicketHandler:SupportTicketHandler,
    getShowSupportTicket:getShowSupportTicket,
    getSupportTicket:getSupportTicket,
    getSupportTicketInfo:getSupportTicketInfo,
    getSupportCategories:getSupportCategories,
    mobileIsUsed:mobileIsUsed,
    UpdateSupportCategoryHandler:UpdateSupportCategoryHandler,
    UpdateSupportTicketHandler:UpdateSupportTicketHandler,
    getSupportStatus:getSupportStatus,
    UpdateSupportStatusHandler:UpdateSupportStatusHandler,
    supportStatusText:supportStatusText,
    supportCategoryText:supportCategoryText,
    GetXchangeRatesHandler:GetXchangeRatesHandler,
    latestRates:latestRates,
    GetScrollLimit:GetScrollLimit,  // functions
    SetScrollLimit:SetScrollLimit,
    scrollLimit:scrollLimit,  // state
    setScrollLimit:setScrollLimit
 



  
    }}>


<div className="panelTopWrapper">
    <div className="panelTotalArea">
    <div className="panelAreaA">
        <div className="panelbuttonWrapper">
            <button className="panelbutton"onClick={()=>panelButtonHandler(1)}>Home</button>
            <button className="panelbutton"onClick={()=>panelButtonHandler(2)}>Services</button>
            <button className="panelbutton"onClick={()=>panelButtonHandler(3)}>Settings</button>
            <button className="panelbutton"onClick={()=>panelButtonHandler(4)}>Users</button>
            <button className="panelbutton" onClick={()=>panelButtonHandler(5)}>BS Invoice</button>
            <button className="panelbutton" onClick={()=>panelButtonHandler(0)}>Dashboard</button>
            <button className="panelbutton" onClick={()=>panelButtonHandler(6)}>Close Sign-up</button>
            <button className="panelbutton" onClick={()=>panelButtonHandler(7)}>Support panel</button>
            <button className="panelbutton" onClick={()=>panelButtonHandler(8)}>Update XchangeRates</button>
            <button className="panelbutton" onClick={()=>panelButtonHandler(9)}>XchangeRates</button>
            <button className="panelbutton" onClick={()=>panelButtonHandler(10)}>Live Scroll Limits</button>

            <button className="panelbutton" onClick={()=>logoutHandler()}>Logout</button>
        </div>
    </div>

<div className="panelAreaB">
    < div className="panelAreaBWrapper">
    <Headers/>
    {activeButton===0?<DashBoardInfo/>:null}
    {activeButton===5?<Invoices/>:null}
    {activeButton===4?<Users/>:null}
    {activeButton==6?<TurnOffSignUp/>:null}
    {activeButton===7?<SupportPanel/>:null}
    {activeButton===9?<XchangeRatesViewer/>:null}
    {activeButton===10?<BsLiveScrollLimits/>:null}
    
           
           </div>
       </div>
    </div>
    <div className="adminSpinnerContainer">
        <div className="adminSpinner">
          <RotatingLines strokeColor="grey" strokeWidth="2" animationDuration="0.75" width="60" visible={spinnerLoading}/>
        </div>
        </div>

      
      {getshowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}
    
</div>


</MyContext.Provider>
    
        )
    
}


function DashBoardInfo(){
    let ctx=useContext(MyContext);
    return(
        <div>
                 <div className="panelAreaBDahsboardWrapper">
                    <div className="panelAreaBDahsboardItem">
                        <div className="panelAreaBDahsboardItemTitle">Total users:</div>
                        <div className="panelAreaBDahsboardItemValue">{ctx.totalUsers}</div>
                        <div className="panelAreaBDahsboardItemTitle">Active services:</div>
                        <div className="panelAreaBDahsboardItemValue">{ctx.activeServices}</div>
                    </div>
                    <div className="panelAreaBDahsboardItem">
                        <div className="panelAreaBDahsboardItemTitle">Total ingredients:</div>
                        <div className="panelAreaBDahsboardItemValue">{ctx.totalIngredients}</div>
                        <div className="panelAreaBDahsboardItemTitle">**</div>
                        <div className="panelAreaBDahsboardItemValue"></div>
                    </div>
                    <div className="panelAreaBDahsboardItem">
                        <div className="panelAreaBDahsboardItemTitle">Total recipes:</div>
                        <div className="panelAreaBDahsboardItemValue">{ctx.totalRecipes&&ctx.totalRecipes>0?ctx.totalRecipes:0}</div>
                        <div className="panelAreaBDahsboardItemTitle">**</div>
                        <div className="panelAreaBDahsboardItemValue"></div>
                    </div>
                    <div className="panelAreaBDahsboardItem">
                        <div className="panelAreaBDahsboardItemTitle">Total products:</div>
                        <div className="panelAreaBDahsboardItemValue">{ctx.totalProducts&&ctx.totalProducts>0?ctx.totalProducts:0}</div>
                        <div className="panelAreaBDahsboardItemTitle">**</div>
                        <div className="panelAreaBDahsboardItemValue"></div>
                    </div>
                    <div className="panelAreaBDahsboardItem">
                        <div className="panelAreaBDahsboardItemTitle">Total productions:</div>
                        <div className="panelAreaBDahsboardItemValue">{ctx.totalProductions && ctx.totalProductions>0?ctx.totalProductions:0}</div>
                        <div className="panelAreaBDahsboardItemTitle">**</div>
                        <div className="panelAreaBDahsboardItemValue"></div>
                    </div>
               </div>

        </div>
    )
}

function Invoices(){
let ctx = useContext(MyContext);

    return(
        <div className="panelHeaderWrapper">
            <div className="panelHeaderTitle">Invoices</div>
            <div className="panelbuttonWrapper">
                <button className="panelbutton2"onClick={()=>ctx.setInvoiceStatusHandler(1)}>Start Invoicing</button>
                <button className="panelbutton2"onClick={()=>ctx.setInvoiceStatusHandler(0)}>Stop Invoicing</button>
                <button className={ctx.getInvoiceStatus?"panelbutton3":"panelbutton2"} >{ctx.getInvoiceStatus===true?'We are invoicing':'We are not invoicing'}</button>
         

            </div>

        </div>
    )
    
}

function Headers(){
    return(
        <div>
              <div className="panelAreaBTitle">Welcome to Bakesoft Panel</div>
              <div className="panelAreaBHeader">This is the Bakesoft Administration panel</div>
        </div>
    )
}

function Users(){
    let ctx = useContext(MyContext);


    return(
        <div className="panelHeaderWrapper">
            <div className="panelHeaderTitle">Users</div>
            <div className="panelbuttonWrapper1">
                <button className="panelbutton2" onClick={()=>ctx.SearchUserHandler()}>Search User</button>
                <button className="panelbutton2" onClick={()=>ctx.listUsersHandler()}>List Users</button>
            </div>

            <hr className="panelLine"></hr>

            {ctx.listUsers===true&&ctx.getSearchUser===false?
            <UserList listUsers={ctx.listUsers} setListUsers={ctx.setListUsers}
            GetUserInfoById={ctx.GetUserInfoById}
            />
            :null}
               {ctx.chosenUserid>0&&ctx.listUsers===false&&ctx.getSearchUser===false?
            <TotalUserInfoPageHeader userInfoById={ctx.userInfoById}/>
            :null}
   
            {ctx.getSearchUser===true&&ctx.listUsers===false?
            <SearchUserPanel/>
            :null}
        </div>
  
    )
}

function UserList(props){
    let {listUsers,setListUsers,GetUserInfoById}=props;
let ctx = useContext(MyContext);
let users=ctx.getUserInformation;


    return(
        <div className="panelHeaderWrapper">
        <div className="panelHeaderTitle">User list</div>
        <div className="panelUsersTopWrapper">
            <div className="panelUsersHeader">
                <div className="panelUsersId">Id</div>
                {ctx.mobileIsUsed===false?(
                <div className="panelUsersEmail">Email</div>
            ):''}
                {ctx.mobileIsUsed===true?(
                <div className="panelUsersName">Name</div>
                ):''}
            </div>
        {listUsers===true?
        <div className="panelUsersListWrapper">
            {users.map((user,index)=>
                <div className="panelUsersWrapper" key={index}>
                    <div className="panelUsersId" onClick={()=>ctx.GetUserInfo(user.userId,user.cc)}>{user.userId}</div>
                    {ctx.mobileIsUsed===false?(
                    <div className="panelUsersEmail">{user.email}</div>
                ):''}
                    {ctx.mobileIsUsed===true?(
                    <div className="panelUsersName">{user.givenName + ' ' + user.familyName}</div>
                    ):''}
                </div>
            )}
       </div>
        :null}
        </div>
    </div>
    )

}

function TotalUserInfoPageHeader(props){
    let ctx = useContext(MyContext);
    
    const [userPanel, setUserPanel] = useState(false);

    const setUserPanelHandler=(e)=>{
        setUserPanel(e);
    }

    return(

     
        <div className="panelHeaderWrapper">
            <div className="panelHeaderTitle">User Information</div>
      
            <div className="panelbuttonWrapper1">
                <button className="panelbutton2" 
                onClick={()=>setUserPanelHandler(1)}>Address Information</button>
                <button className="panelbutton2"
                onClick={()=>setUserPanelHandler(2)}>Invoices</button>
                <button className="panelbutton2"
                onClick={()=>setUserPanelHandler(3)}>Statistics</button>
                <button className="panelbutton2"
                onClick={()=>setUserPanelHandler(4)}>Images</button>
                   <button className="panelbutton2"
                onClick={()=>setUserPanelHandler(5)}>Turn off/on user</button>
                     <button className="panelbutton2"
                onClick={()=>setUserPanelHandler(6)}>Services</button>
        
            </div>

{userPanel===1?<UserAddressInfo userInfoById={ctx.userInfoById}/>:null}
{userPanel===2?<UserInvoicesHeader/>:null}
{userPanel===5?<UserTurnOffPanel/>:null}
{userPanel===6?<UserServices/>:null}

        </div>



    )
}

function UserAddressInfo(props){
    let {userInfoById}=props;
    let ctx = useContext(MyContext);
    let userAddressInfo=userInfoById[0];
    return(
        <div className="panelHeaderWrapper">
            <div className="panelHeaderTitle">User Address Information</div>
            <div className="panelUserAdressWrapper">
                <div className="panelUserAdressTitle">Email:&nbsp;<div className="panelUserAdressValue">{userAddressInfo&&userAddressInfo.length>0?userAddressInfo[0].email:''}</div></div>
               
                <div className="panelUserAdressTitle">Name:&nbsp;<div className="panelUserAdressValue">{userAddressInfo&&userAddressInfo.length>0?userAddressInfo[0].givenName +' ' + userAddressInfo[0].familyName:''}</div></div>
               
                <div className="panelUserAdressTitle">Phone:&nbsp;<div className="panelUserAdressValue">{userAddressInfo&&userAddressInfo.length>0?userAddressInfo[0].phone:''}</div></div>
               
                <div className="panelUserAdressTitle">Address:&nbsp;<div className="panelUserAdressValue">{userAddressInfo&&userAddressInfo.length>0?userAddressInfo[0].address:''}</div></div>

                <div className="panelUserAdressTitle">Postal code:&nbsp;<div className="panelUserAdressValue">{userAddressInfo&&userAddressInfo.length>0?userAddressInfo[0].postalCode:''}</div></div>

                <div className="panelUserAdressTitle">City:&nbsp;<div className="panelUserAdressValue">{userAddressInfo&&userAddressInfo.length>0?userAddressInfo[0].city:''}</div></div>

                <div className="panelUserAdressTitle">Region:&nbsp;<div className="panelUserAdressValue">{userAddressInfo&&userAddressInfo.length>0?userAddressInfo[0].region:''}</div></div>

                <div className="panelUserAdressTitle">Country:&nbsp;<div className="panelUserAdressValue">{userAddressInfo&&userAddressInfo.length>0?userAddressInfo[0].country:''}</div></div>

                <div className="panelUserAdressTitle">country code:&nbsp;<div className="panelUserAdressValue">{userAddressInfo&&userAddressInfo.length>0?userAddressInfo[0].cc:''}</div></div>

                <div className="panelUserAdressTitle">Company:&nbsp;<div className="panelUserAdressValue">{userAddressInfo&&userAddressInfo.length>0?userAddressInfo[0].company:''}</div></div>



            </div>
        

        </div>
        
    )
}

function UserInvoicesHeader(){
    let ctx = useContext(MyContext);

    const [userInvoiceInfo, setUserInvoiceInfo] = useState([{}]);
    const [showInvoiceInfo, setShowInvoiceInfo] = useState(false);
    const [userpaymentinfo, setUserPaymentInfo] = useState([{}]);

    const ShowInvoiceHandler=(invoiceId)=>{
        let userinfobyid=ctx.userInfoById[2]
        let paymentinfo=ctx.userInfoById[1];    
        let userpaymentinfo=paymentinfo.filter((payment)=>payment.fakturaid===invoiceId);
        let userInvoiceInfo=userinfobyid.filter((invoice)=>invoice.fakturaid===invoiceId);

        setUserInvoiceInfo(userInvoiceInfo);
        setShowInvoiceInfo(true);
        setUserPaymentInfo(userpaymentinfo);
    }

    function formatDateTime(dateString) {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
        return date.toLocaleString('en-US', options);
    }

     

    return(
        
            <div className="panelHeaderWrapper">
                <div className="panelHeaderTitle">User Invoices</div>
                  <div className="panelUsersInvoicesWrapper">
                    <div className="panelUsersHeaderWrapper">
                        <div className="panelUsersInvoiceHeader">Id</div>
                     
                        <div className="panelUsersInvoiceHeader">Date</div>
                    

                        <div className="panelUsersInvoiceHeader">Amount</div>
                        <div className="panelUsersInvoiceHeader">Status</div>
                    </div>

                  </div>
                
                    <div className="panelUsersInvoiceTopListWrapper">
                    {ctx.userInfoById[1]&&ctx.userInfoById[1].length>0?ctx.userInfoById[1].map((invoice,index)=>
                        <div className="panelUsersInvoiceListWrapper" key={index}>
                            <div className="panelUsersInvoice" onClick={()=>ShowInvoiceHandler(invoice.fakturaid)}>{invoice.fakturaid}</div>
                        
                            <div className="panelUsersInvoice">{invoice.createDate ? formatDateTime(invoice.createDate) : ''}</div>
                
                            <div className="panelUsersInvoice">{invoice.summa}</div>
                            <div className="panelUsersInvoice">{invoice.payStatus===1?'Not payed':'Payed'}</div>
                        </div>
                    ):''}
                    </div>

                    {showInvoiceInfo&&userInvoiceInfo.length>0?<UserInvoiceInfoPanel userInvoiceInfo={userInvoiceInfo}
                    userpaymentinfo={userpaymentinfo}
                    />:null}
                </div>
            
            
   
   
    )
}


function UserInvoiceInfoPanel(props){
    let {userInvoiceInfo,userpaymentinfo}=props;

    let ctx = useContext(MyContext);


    function formatDateTime(dateString) {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
        return date.toLocaleString('en-US', options);

    
    }
    return(
        <div className="panelInvoiceInfoWrapper">
            <div className="panelHeaderTitle">User Invoice:{userInvoiceInfo&&userInvoiceInfo.length>0?userInvoiceInfo[0].fakturaid:''}</div>
            <div className="panelUserInvoiceInfoWrapper">
                <div className="panelUserInvoiceInfoTitle">Invoice Date:&nbsp;{userInvoiceInfo&&userInvoiceInfo.length>0?formatDateTime(userInvoiceInfo[0].createDate):''}</div>
                <div className="panelUserInvoiceInfoTitle">Invoice Amount:&nbsp;{userInvoiceInfo&&userInvoiceInfo.length>0?userInvoiceInfo[0].summa:''}</div>
                <div className="panelUserInvoiceInfoTitle">Invoice Vatsum:&nbsp;{userInvoiceInfo&&userInvoiceInfo.length>0?userInvoiceInfo[0].vatSum:''}</div>
                <div className="panelUserInvoiceInfoTitle">Serviceid:&nbsp;{userInvoiceInfo&&userInvoiceInfo.length>0?userInvoiceInfo[0].serviceid:''}</div>
                <div className="panelUserInvoiceInfoTitle">Service name:&nbsp;{userInvoiceInfo&&userInvoiceInfo.length>0?userInvoiceInfo[0].benaemn:''}</div>
                <div className="panelUserInvoiceInfoTitle" 
                onClick={()=>ctx.DownLoadPdfHandler(userInvoiceInfo&&userInvoiceInfo.length>0?userInvoiceInfo[0].userid:'',userInvoiceInfo&&userInvoiceInfo.length>0?userInvoiceInfo[0].fakturaid:'')}
                >
                    <FaFilePdf style={{ color: 'red', fontSize: '16px' }} />
                    
                    PdfInvoice</div>
                <div className="panelUserInvoiceInfoTitle">Paypal transactionid:{userpaymentinfo[0].paypaltransactionId>0?userpaymentinfo[0].paypaltransactionId:'None'}</div>
                <div className="panelUserInvoiceInfoTitle">Klarna transactionid:{userpaymentinfo[0].klarnaorderId>0?userpaymentinfo[0].klarnaorderId:'None'}</div>
            </div>
        </div>
    )
}

function SearchUserPanel(){
    let ctx = useContext(MyContext);
    return(
        <div className="panelSearchUserTop">
            <div className="panelHeaderSearchTitle">Search User</div>
            <div className="panelSearchUserWrapper">
                <input type="text" placeholder="user name or id" className="panelSearchUserInput"
                onChange={(e)=>ctx.SearchUserTextHandler(e.target.value)}
                />
                <button className="panelSearchUserButton"
                onClick={()=>ctx.PanelSearchUser()}
                
                >Search</button>
            </div>
        {ctx.getShowSearchList===true?<SearchUserResultList/>:null}
        </div>
    )
}

function SearchUserResultList(){
    let ctx = useContext(MyContext);
    let users=ctx.userInfoById[0];

    const [showPanel, setShowPanel] = useState(false);
    
    const ShowPanel=()=>{
        setShowPanel(true);
    }

    return(
        <div className="panelSearchUserListWrapper">
            <div className="panelSearchUserListHeader">
                <div className="panelSearchUserListId">Id</div>
                <div className="panelSearchUserListName">Name</div>
                <div className="panelSearchUserListEmail">Email</div>
            </div>
            <div className="panelSearchUserListWrapper">
                {users.map((user,index)=>
                    <div className="panelSearchUserList" key={index}>
                        <div className="panelSearchUserListId" onClick={()=>ShowPanel}>{user.userId}</div>
                        <div className="panelSearchUserListName">{user.givenName + ' ' + user.familyName}</div>
                        <div className="panelSearchUserListEmail">{user.email}</div>
                    </div>
                )}


        </div>
        <TotalUserInfoPageHeader userInfoById={ctx.userInfoById}/>
    </div>

    )
}

function UserTurnOffPanel(){
    let ctx = useContext(MyContext);
    return(
        <div className="panelHeaderWrapper">
            <div className="panelHeaderTitle">Turn off/on user</div>
            <div className="panelUserTurnOffWrapper">
                <div className="panelUserTurnOffTitle">Are you sure you want to turn off the user?</div>
                <div className="panelUserTurnOffButtonWrapper">
                    <button className="panelUserTurnOffButton"
                    onClick={() => {ctx.TurnOfUserHandler(1)}}
                    >Turn Off</button>
                    <button className="panelUserTurnOffButton"
                    onClick={() => {ctx.TurnOfUserHandler(2)}}
                    >Turn On</button>
                </div>
            </div>
        </div>
    )
}

function UserServices(){
    let ctx = useContext(MyContext);
    let services=ctx.userInfoById[3];


    const [showServiceInfoPanel, setShowServiceInfoPanel] = useState(false);
    const [serviceid, setServiceId] = useState(0);

    const ShowServiceInfoPanelHandler=(serviceid)=>{
    setShowServiceInfoPanel(true);
    setServiceId(serviceid);
    }

  
    return(
        <div className="panelServicesHeaderWrapper">
            <div className="panelHeaderTitle">User Services</div>
            <div className="panelServicesWrapper">
                <div className="panelServicesHeaderId">Id</div>
                <div className="panelServicesHeaderName">Service name</div>
                <div className="panelServicesHeaderStatus">Status</div>
                <div className="panelServicesHeaderUpdate">Update</div> 
        </div>
        <div className="panelServicesListWrapper">
            {services.map((service,index)=>
                <div className="panelServicesList" key={index}>
                    <div className="panelServicesId">{service.serviceid}</div>
                    <div className="panelServicesName"
                    
                    onClick={()=>ShowServiceInfoPanelHandler(service.serviceid)}
                    >{service.benaemn}</div>
                    <div className="panelServicesStatus">{service.status===1?'Active':'Not active'}</div>
                    <div className="panelServicesUpdate">Update</div>
                </div>
            )}


    </div>
    {showServiceInfoPanel===true?<ShowServiceInfoPanel serviceid={serviceid}/>:null}
    </div>

    )
}

function ShowServiceInfoPanel(props){
    let{serviceid}=props;
    let ctx = useContext(MyContext);
    let services=ctx.userInfoById[3];
    let serviceInfo=services.filter((service)=>service.serviceid===serviceid);
    let rowId=services[0].rowId;
    console.log(serviceInfo[0].rowId);
    
    function formatDateTime(dateString) {   
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
        return date.toLocaleString('en-SV', options);
    }
    return(
        <div className="panelServicesInfoDetailWrapper">
            <div className="panelHeaderTitle">Service Information</div>
            <div className="panelServiceInfoWrapper">
                <div className="panelServiceInfoTitle">Id:&nbsp;{serviceInfo&&serviceInfo.length>0?serviceInfo[0].serviceid:''} </div>
                <div className="panelServiceInfoTitle">Name:&nbsp;{serviceInfo&&serviceInfo.length>0?serviceInfo[0].benaemn:''}</div>
                <div className="panelServiceInfoTitle">Start Date:&nbsp;{serviceInfo&&serviceInfo.length>0?formatDateTime(serviceInfo[0].dateFrom):''}</div>
                <div className="panelServiceInfoTitle">End Date:&nbsp;{serviceInfo&&serviceInfo.length>0?formatDateTime(serviceInfo[0].dateTo):''}</div>
                <div className="panelServiceInfoTitle">Create date:&nbsp;{serviceInfo&&serviceInfo.length>0?formatDateTime(serviceInfo[0].createDate):''}</div>
            </div>
            <div className="panelServiceInfoButtonWrapper">
            <button className="panelServiceInfoButton"
            onClick={()=>ctx.ServicesHandler(1,serviceInfo[0].rowId)}
            >Turn Off</button>
            <button className="panelServiceInfoButton"
            onClick={()=>ctx.ServicesHandler(2,serviceInfo[0].rowId)}
            >Turn On</button>
            <button className="panelServiceInfoButton"
            onClick={()=>ctx.ServicesHandler(3,serviceInfo[0].rowId)}
            >Extend 1 month</button> 

            </div>

        </div>
    )

}

function TurnOffSignUp(){
    let ctx = useContext(MyContext);
    let signUp=ctx.getSignUpStatus&&ctx.getSignUpStatus.length>0?ctx.getSignUpStatus:0;

    return(
        <div className="panelHeaderWrapper">
            <div className="panelHeaderTitle">SignUp page Status</div>
            <div className="panelCloseSignUpWrapper">
                <div className="panelCloseSignUpTitle">Open or close the sign up page</div>
                <div className="panelCloseSignUpButtonWrapper">
                    <button className="panelCloseSignUpButton"
                    onClick={()=>{ctx.UpdateSignUpStatus(1)}}
                    >Close</button>
                    <button className="panelCloseSignUpButton"
                    onClick={()=>{ctx.UpdateSignUpStatus(0)}}
                    >Open</button>
                </div>
         
            </div>
        </div>
    )
}

function SupportPanelHeader(){
    let ctx = useContext(MyContext);

    return(
        <div className="panelHeaderWrapper">
            <div className="panelHeaderTitle">Support Panel</div>
</div>
    )

}

function SupportPanel(){
    let ctx = useContext(MyContext);
    let supportStatus=ctx.getSupportStatus;
    let supportlist=ctx.supportList;
   




    return(
        <div className="panelSupportTop">
        <div className="panelHeaderTitle">Support Panel</div>
        <div className="panelSupportHeaderWrapper">
            <div className="panelSupportHeaderUserId">S-ticket</div>
            <div className="panelSupportHeaderName">Name</div>
            <div className="panelSupportHeaderDate">Date</div>
            <div className="panelSupportHeaderStatus">Status</div>
        </div>
        <div className="panelSupportListWrapper">
            {ctx.supportList && ctx.supportList.length > 0 ? ctx.supportList.map((support, index) => {
                const formattedDate = formatDateTime(support.createDate);
                return (
                    <div className="panelSupportList" key={index}>
                        <div className="panelSupportUserId"
                        onClick={()=>ctx.SupportTicketHandler(support.supportid)}
                        >{support.supportid}</div>
                        <div className="panelSupportName">{support.givenName + ' ' + support.familyName}</div>
                        {ctx.mobileIsUsed===false?(
                        <div className="panelSupportDate">{formattedDate}</div>
                    ):''}
                    {ctx.mobileIsUsed===true?(
                        <div className="panelSupportDate">{formattedDate.substring(0,10)}</div>
                    ):''}
                        <div className="panelSupportStatus">{support.statusName}</div>
                    </div>
                );
            }) : ''}
        </div>
        {ctx.getShowSupportTicket===true?<SupportTicketDetail supportTicketId={ctx.getSupportTicket}/>:null}
    
        
    
    </div>
);
}

function SupportTicketDetail(props){
    let {supportTicketId}=props;
let ctx = useContext(MyContext);
let supportList=ctx.supportList.filter((support)=>support.supportid===supportTicketId);

let chosenCategory=ctx.getSupportCategories.filter((category)=>category.kategoriid===supportList[0].kategoriid)
let chosenBenaemn=chosenCategory&&chosenCategory.length>0?chosenCategory[0].benaemn:'';
let chosenCategoryId=chosenCategory&&chosenCategory.length>0?chosenCategory[0].kategoriid:'';
let supportStatus=ctx.getSupportStatus.filter((status)=>status.statusId===supportList[0].Istatus);
let supportStatusBenaemn=supportStatus[0].benaemn;
let supportStatusId=supportStatus[0].statusId;




    return(
        <div className="panelSupportTicketDetailWrapper">
            <div className="panelHeaderTitle">Support Ticket</div>
            <div className="panelSupportTicketDetailInfo">
                <div className="panelSupportTicketDetailTitle">Ticket id:{supportList[0].supportid}</div>
                <div className="panelSupportTicketDetailTitle">Name:{supportList[0].givenName + ' ' + supportList[0].familyName}</div>
                <textarea className="panelSupportTicketDetailTextArea" value={supportList&&supportList.length>0?supportList[0].benaemn:''} 
                onChange={(e)=>ctx.SupportTextAreaHandler(e.target.value,
                    supportList&&supportList.length>0?supportList[0].supportid:'')}
                ></textarea>
                <div className="panelSupportTicketSelectTop">{ctx.supportCategoryText}
                <select className="panelSupportTicketDetailSelect" onChange={(e)=>ctx.UpdateSupportCategoryHandler(e.target.value,supportTicketId)}>
                <option value={chosenCategoryId}>{chosenBenaemn}</option>
                {ctx.getSupportCategories.map((category,index)=>
                    <option key={index} value={category.kategoriid}>{category.benaemn}</option>
                )}
                    </select>
                    {ctx.supportStatusText}
                    <select className="panelSupportTicketDetailSelect"
                    onChange={(e)=>ctx.UpdateSupportStatusHandler(e.target.value,supportTicketId)}
                    >
                    <option value={supportStatusId}>{supportStatusBenaemn}</option>
                        {ctx.getSupportStatus.map((status,index)=>
                            <option key={index} value={status.statusId}>{status.benaemn}</option>
                        )}


                    </select>
                </div>
                <div className="panelSupportTicketDetailButtonWrapper">
                <button className="panelSupportTicketDetailButton"
                onClick={()=>ctx.UpdateSupportTicketHandler(supportList[0].supportid)}
                >Update</button>
            
             </div>
           
            </div>
        </div>
    )
}

function XchangeRatesViewer(){
    let ctx = useContext(MyContext);
    console.log(ctx.latestRates)
    return(
        <><div className="panelHeaderWrapper">
            <div className="panelHeaderTitle">Xchange Rates</div>
        </div><div className="panelXchangeRatesWrapper">
                <div className="panelXchangeRatesButton"
                onClick={()=>ctx.GetXchangeRatesHandler()}
                >XchangeRates</div>
            </div>

            <div className="panelXchangeHeaderWrapper">
                <div className="panelXchangeName">Name</div>
                <div className="panelXchangeName">Rate</div>
                <div className="panelXchangeCurrencyName">CurrencyName</div>
                <div className="panelXchangeName">CurrencyShort</div>
                
            </div>
            <div className="panelXchangeListWrapper">
                {ctx.latestRates&&ctx.latestRates.length>0?ctx.latestRates.map((rate,index)=>
                    <div className="panelXchangeList" key={index}>
                        <div className="panelXchangeName">{rate.ccName}</div>
                        <div className="panelXchangeName">{rate.xchangeRate}</div>
                        <div className="panelXchangeCurrencyName">{rate.currencyName}</div>
                        <div className="panelXchangeName">{rate.currencyShort}</div>
                    </div>
                ):''}
            </div>
            
            
            
            
            
            
            </>

    )
}

function BsLiveScrollLimits(){
let ctx=useContext(MyContext);


useEffect(()=>{
    ctx.GetScrollLimit();
},[])


const SetScrollLimitHandler=(val)=>{
    ctx.setScrollLimit(val);
}


    return(
    <div className="panelScrollLimitWrapper">
        <div className="panelScrollHeaderWrapper">
            <div className="panelScrollHeaderTitle">Live Scroll Limits</div>
        </div>
        <div className="panelScrollLimitWrapper">
            <div className="panelScrollLimitTitle">Set limits for live scroll, if the system is overloaded or there is other reasons to restict the usage of the 
                live.bakesoft.se website. The limits will be set for the whole system and not for individual users.
            </div>
            <input type="text" className="panelScrollLimitInput" placeholder="Enter limit"
            value={ctx.scrollLimit}
            onChange={(e)=>SetScrollLimitHandler(e.target.value)}
            />
            <button className="panelScrollLimitButton"
            onClick={()=>ctx.SetScrollLimit()}
            >Set limits</button>
        </div>
    </div>
        
    )
}


export {Panel,Invoices,Headers,DashBoardInfo,Users,UserList,TotalUserInfoPageHeader,
    UserAddressInfo,UserInvoicesHeader,UserInvoiceInfoPanel,SearchUserPanel,SearchUserResultList,XchangeRatesViewer,
    TurnOffSignUp,SupportPanelHeader,SupportPanel,SupportTicketDetail,UserTurnOffPanel,UserServices,ShowServiceInfoPanel,
    BsLiveScrollLimits

}